import $ from 'jquery'

const banner = $('.js-banner');
const main = $('main');
const header = $('.js-header');

function openBar(){
  if (!banner.length || banner.hasClass('is-closed')) return false
  banner.addClass('is-loaded')
  
  main.attr('style', '')
  const mainWrap = main.css('paddingTop');
  const mainPadding = parseInt(mainWrap) + banner.outerHeight();
  header.css('paddingTop', `${banner.outerHeight()}px`);
  main.css('paddingTop', `${mainPadding}px`);
  banner.css('top', 0);
}

function closeBar(event) {
  event.preventDefault()
  banner.removeClass('is-loaded');
  header.css('paddingTop', 0);
  main.attr('style', '')
  banner.css('top', `-${banner.outerHeight()}px`);
  banner.addClass('is-closed');
}

$(document).ready( openBar )
$(document).on('click', '.banner__close', closeBar )
$(document).on('click', '.js-hamburger', closeBar )
$(window).on('resize', openBar )