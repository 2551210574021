import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().next().slideToggle('fast');
  el.parent().parent().toggleClass('active');
}

$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
$(window).on('load', function() {
  var initial = $('.js-accordion-item')[0]
  $(initial).find('button').trigger('click')
})
