import $ from 'jquery'

const $doc = $(document)
let isPlaying = false;

const audio = $('.js-audio')[0],
      currentTime = $('.audio-player__time'),
      duration = $('.audio-player__duration'),
      playIcon = `
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_12)">
      <path d="M24.6827 46.5154C36.6129 46.5154 46.2843 36.8895 46.2843 25.0154C46.2843 13.1413 36.6129 3.51541 24.6827 3.51541C12.7525 3.51541 3.08109 13.1413 3.08109 25.0154C3.08109 36.8895 12.7525 46.5154 24.6827 46.5154Z" fill="#BDE226"/>
      <path d="M32.9687 25.0153L20.5394 32.1577V17.873L32.9687 25.0153Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1_12">
      <rect width="50" height="50" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      `,
      pauseIcon = `
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_2)">
      <path d="M24.6827 46.5154C36.6129 46.5154 46.2843 36.8895 46.2843 25.0154C46.2843 13.1413 36.6129 3.51541 24.6827 3.51541C12.7525 3.51541 3.08109 13.1413 3.08109 25.0154C3.08109 36.8895 12.7525 46.5154 24.6827 46.5154Z" fill="#BDE226"/>
      <path d="M18.4426 32.6516H22.8146V17.3496H18.4426V32.6516ZM27.1866 17.3496V32.6516H31.5586V17.3496H27.1866Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1_2">
      <rect width="50" height="50" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      `;

function initAudioPlayer() {
  if($('.js-audio').length) duration.text(calculateTime(audio.duration));
}

function calculateTime(totalSeconds) {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const zeroFill = n => n > 9 ? n : `0${n}`;
  
  return `${zeroFill(hours)}:${zeroFill(minutes)}:${zeroFill(seconds)}`;
}

function toggleAudio () {
  if (isPlaying) {
    audio.pause()
    $(this).html(playIcon)
  } else {
    audio.play()
    $(this).html(pauseIcon)
    setInterval(function () {
      currentTime.text(calculateTime(audio.currentTime))
      if (audio.currentTime === audio.duration) {
        $('.js-play-button').html(playIcon)
        isPlaying = false;
      }
    }, 0);
  }
  isPlaying = !isPlaying;
}

function removeSecs() {
  audio.currentTime-= 30.0;
}

function addSecs() {
  audio.currentTime+= 30.0;
}

$doc.ready( initAudioPlayer )
$doc.on('click', '.js-play-button', toggleAudio )
$doc.on('click', '.audio-player__less', removeSecs )
$doc.on('click', '.audio-player__more', addSecs )